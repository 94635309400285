window.axios = require('axios')
import router  from '../router'
export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token'),
        username: localStorage.getItem('username'),
        role: localStorage.getItem('role')
    },
    mutations: {
        SET_TOKEN (state, token) {
            state.token = token
            localStorage.setItem('token', token)
        },
        SET_USERNAME (state, username) {
            state.username = username
            localStorage.setItem('username', username)
        },
        SET_ROLE (state, role) {
            state.role = role
            localStorage.setItem('role', role)
        },
        REMOVE_TOKEN_USERNAME_ROLE(state) {
            localStorage.removeItem('token')
            localStorage.removeItem('username')
            localStorage.removeItem('role')
            state.token = '', state.username = '', state.role
            
        }
    },
    actions: {
        async signIn({commit}, credentials){
            let {data} = await axios.post('/login', credentials)
            commit('SET_TOKEN', data.token)
            commit('SET_USERNAME', data.username)
            commit('SET_ROLE', data.role)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token
            router.push({name: 'Dashboard'})
        },

        async logOut({commit}) {
            commit('REMOVE_TOKEN_USERNAME_ROLE')
            axios.defaults.headers.common['Authorization'] = ''
            router.push({name: 'Login'})
        }
    },
    getters: {
        token: state => state.token,
        username: state => state.username,
        role: state => state.role
    }
}