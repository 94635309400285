<template>
    <nav>
        <v-app-bar app color="white" elevation="2" rounded>
            <v-app-bar-nav-icon v-on:click="drawer = !drawer"></v-app-bar-nav-icon>

            <h2> {{ pageTitle ? pageTitle : 'کۆمپانیای ڕۆژ' }}</h2>
            <v-spacer></v-spacer>
            <v-btn v-if="token" text color="error" @click="logout()">دەرچوون<v-icon>mdi-logout</v-icon></v-btn>
        </v-app-bar>
        <v-navigation-drawer v-if="token" expand-on-hover app hidden v-model="drawer" class="fill-height"
            mini-variant-width="80" width="270" :right="this.$vuetify.rtl">
            <v-list-item>
                <v-list-item-icon>
                    <v-avatar color="blue accent-2" size="30"><v-icon> mdi-account</v-icon></v-avatar>
                </v-list-item-icon>

                <v-list-item-content>
                    <h3>{{ username }}</h3>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <v-list nav>
                <v-list-item-group color="blue accent-2">
                    <v-list-item link v-if="role != 'representative' && role != 'local'" to="/" class="scale">
                        <v-list-item-icon>
                            <v-icon> mdi-view-dashboard </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>داشبۆرد</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link v-if="role != 'representative'" to="/supplier" class="scale">
                        <v-list-item-icon>
                            <v-icon> mdi-domain </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>کۆمپانیاکان</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-group no-action link v-if="role != 'representative'">
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <v-icon> mdi-dots-grid</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>کاڵاکان</v-list-item-title>
                        </template>
                        <v-list-item link v-if="role != 'representative'" to="/item/unit" class="scale">
                            <v-list-item-icon>
                                <v-icon> mdi-sitemap </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>یەکەکان</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link v-if="role != 'representative'" to="/item/item_category" class="scale">
                            <v-list-item-icon>
                                <v-icon> mdi-shape </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>جۆرەکان</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link v-if="role != 'representative'" to="/item" class="scale">
                            <v-list-item-icon>
                                <v-icon> mdi-dots-grid </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>کاڵاکان</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item link v-if="role != 'representative'" to="/purchase" class="scale">
                        <v-list-item-icon>
                            <v-icon> mdi-cart </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>کڕین</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link to="/sale" class="scale">
                        <v-list-item-icon>
                            <v-icon> mdi-receipt </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>فرۆشتن</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item link v-if="role != 'representative'" to="/customer" class="scale">
                        <v-list-item-icon>
                            <v-icon> mdi-account-group </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>کڕیار</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link to="/give_debt" class="scale">
                        <v-list-item-icon>
                            <v-icon> mdi-currency-usd </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>وەرگرتنەوەی قەرز</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link v-if="role != 'representative'" to="/return_debt" class="scale">
                        <v-list-item-icon>
                            <v-icon> mdi-currency-usd </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>گەڕاندنەوەی قەرز</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link to="/debtors" class="scale">
                        <v-list-item-icon>
                            <v-icon>
                                mdi-account-multiple
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>قەرزارەکان</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link v-if="role != 'representative'" to="/disposal" class="scale">
                        <v-list-item-icon>
                            <v-icon> mdi-delete </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>تەلەف</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-group no-action link v-if="role != 'representative'">
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <v-icon> mdi-account</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>مەندووب</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item link v-if="role != 'representative'" to="/representative" class="scale">
                            <v-list-item-icon>
                                <v-icon> mdi-account </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>مەندووەبەکان</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link v-if="role != 'representative'" to="/representative/transfer" class="scale">
                            <v-list-item-icon>
                                <v-icon> mdi-truck </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>گواستنەوەی کاڵا</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>

                    <v-list-group no-action link v-if="role != 'representative'">
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <v-icon> mdi-cash</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>خەرجییەکان</v-list-item-title>
                        </template>
                        <v-list-item link v-if="role != 'representative'" to="/expense/expense_category" class="scale">
                            <v-list-item-icon>
                                <v-icon> mdi-shape </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>جۆرەکان</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link v-if="role != 'representative'" to="/expense" class="scale">
                            <v-list-item-icon>
                                <v-icon> mdi-cash </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>خەرجییەکان</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-group no-action link v-if="role != 'representative'">
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <v-icon> mdi-chart-box</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>ڕاپۆرتەکان</v-list-item-title>
                        </template>
                        <v-list-item link v-if="role != 'representative'" to="/report/inventory" class="scale">
                            <v-list-item-icon>
                                <v-icon> mdi-store </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>جەردی مەخزەن</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link v-if="role != 'representative' && role != 'local'" to="/report/profit"
                            class="scale">
                            <v-list-item-icon>
                                <v-icon>mdi-currency-usd</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>قازانج</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item link v-if="role != 'representative' && role != 'local'" to="/report/expense"
                            class="scale">
                            <v-list-item-icon>
                                <v-icon>mdi-cash</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>خەرجی</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item link v-if="role == 'representative'" to="/report/representative/inventory" class="scale">
                        <v-list-item-icon>
                            <v-icon> mdi-store </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>جەردی مەخزەن</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

    </nav>
</template>

<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
export default {
    name: 'navbar',
    props: ['pageTitle'],
    data: () => ({
        drawer: false,
        reports: false,
    }),
    methods: {
        ...mapActions({
            logOut: 'auth/logOut'
        }),
        logout() {
            this.logOut()
        }
    },
    computed: {
        ...mapGetters('auth', [
            'token',
            'username',
            'role'
        ])
    }
}
</script>

<style >
a {
    text-decoration: none;
}

#ii:hover {
    transform: scale3d(1.4, 1.4, 1.4);
    transition-duration: 0.5s;
}

.scale:hover {
    transform: scale3d(1.1, 1.1, 1.1);
    transition-duration: 0.4s;
}

/* width */
::-webkit-scrollbar {
    width: 7px;

}

/* Track */
::-webkit-scrollbar-track {
    background: rgb(133, 133, 133);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(133, 133, 133);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>