import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store"; // <-- aliased path

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: { requiresAuth: true, title: "داشبۆرد" },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/supplier",
    name: "Supplier",
    component: () => import("../views/Supplier.vue"),
    meta: { requiresAuth: true, title: "کۆمپانیاکان" },
  },
  {
    path: "/item/item_category",
    name: "ItemCategory",
    component: () => import("../views/Item/ItemCategory.vue"),
    meta: { requiresAuth: true, title: "جۆری کاڵاکان" },
  },
  {
    path: "/item/unit",
    name: "Unit",
    component: () => import("../views/Item/Unit.vue"),
    meta: { requiresAuth: true, title: "یەکەکان" },
  },
  {
    path: "/item",
    name: "Item",
    component: () => import("../views/Item/Item.vue"),
    meta: { requiresAuth: true, title: "کاڵاکان" },
  },
  {
    path: "/branch",
    name: "Branch",
    component: () => import("../views/Branch.vue"),
    meta: { requiresAuth: true, title: "کاڵاکان" },
  },
  {
    path: "/customer",
    name: "Customer",
    component: () => import("../views/Customer.vue"),
    meta: { requiresAuth: true, title: "کڕیارەکان" },
  },
  {
    path: "/employee",
    name: "Employee",
    component: () => import("../views/Employee.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/expense/expense_category",
    name: "ExpenseCategory",
    component: () => import("../views/Expense/ExpenseCategory.vue"),
    meta: { requiresAuth: true, title: "جۆری خەرجییەکان" },
  },
  {
    path: "/expense",
    name: "Expense",
    component: () => import("../views/Expense/Expense.vue"),
    meta: { requiresAuth: true, title: "خەرجییەکان" },
  },
  {
    path: "/purchase",
    name: "Purchase",
    component: () => import("../views/Purchase.vue"),
    meta: { requiresAuth: true, title: "کڕینەکان" },
  },
  {
    path: "/sale",
    name: "Sale",
    component: () => import("../views/Sale.vue"),
    meta: { requiresAuth: true, title: "فرۆشتنەکان" },
  },
  {
    path: "/disposal",
    name: "Disposal",
    component: () => import("../views/Disposal.vue"),
    meta: { requiresAuth: true, title: "تەلەفەکان" },
  },
  {
    path: "/give_debt",
    name: "GiveDebt",
    component: () => import("../views/GiveDebt.vue"),
    meta: { requiresAuth: true, title: "وەرگرتنەوەی قەرز" },
  },
  {
    path: "/debtors",
    name: "Debtors",
    component: () => import("../views/Debtors.vue"),
    meta: { requiresAuth: true, title: "قەرزارەکان" },
  },
  {
    path: "/give_debt/:give_debt_id",
    name: "PrintGiveDebt",
    component: () => import("../views/PrintGiveDebt.vue"),
    meta: { requiresAuth: true, title: "وەرگرتنەوەی قەرز" },
  },
  {
    path: "/return_debt",
    name: "ReturnDebt",
    component: () => import("../views/ReturnDebt.vue"),
    meta: { requiresAuth: true, title: "گەڕاندنەوەی قەرز" },
  },
  {
    path: "/report/inventory",
    name: "InventoryReport",
    component: () => import("../views/Report/Inventory.vue"),
    meta: { requiresAuth: true, title: "ڕاپۆرتی کۆگا" },
  },

  {
    path: "/report/inventoryPrint",
    name: "InventoryPrint",
    component: () => import("../views/Report/InventoryPrint.vue"),
    meta: { requiresAuth: true, title: "ڕاپۆرتی کۆگا" },
  },
  {
    path: "/report/profit",
    name: "Profit",
    component: () => import("../views/Report/Profit.vue"),
    meta: { requiresAuth: true, title: "ڕاپۆرتی قازانج" },
  },
  {
    path: "/report/customer/:customer_id/:from/:to",
    name: "CustomerInvoice",
    component: () => import("../views/Report/CustomerInvoice.vue"),
    meta: { requiresAuth: true, title: "ڕاپۆرتی کڕیار" },
  },
  {
    path: "/report/supplier/:supplier_id/:from/:to",
    name: "SupplierPurchase",
    component: () => import("../views/Report/SupplierPurchase.vue"),
    meta: { requiresAuth: true, title: "ڕاپۆرتی کڕیار" },
  },
  {
    path: "/report/supplier/:supplier_id",
    name: "SupplierHistory",
    component: () => import("../views/Report/SupplierHistory.vue"),
    meta: { requiresAuth: true, title: "ڕاپۆرتی کۆمپانیا" },
  },
  {
    path: "/report/expense",
    name: "ExpenseReport",
    component: () => import("../views/Report/Expense.vue"),
    meta: { requiresAuth: true, title: "ڕاپۆرتی خەرجی" },
  },
  {
    path: "/representative",
    name: "Representative",
    component: () => import("../views/Representative/Representative.vue"),
    meta: { requiresAuth: true, title: "مەندووبەکان" },
  },
  {
    path: "/representative/transfer",
    name: "RepresentativeTransfer",
    component: () => import("../views/Representative/TransferItem.vue"),
    meta: { requiresAuth: true, title: "گواستنەوەی کاڵا" },
  },
  {
    path: "/report/representative/inventory",
    name: "InventoryRepresentative",
    component: () => import("../views/Report/RepresentativeInventory.vue"),
    meta: { requiresAuth: true, title: "جەرد" },
  },
  {
    path: "/sale/:sale_id",
    name: "PrintSale",
    component: () => import("../views/PrintSale.vue"),
    meta: { requiresAuth: true, title: " وەسڵی فرۆشتن" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const token = store.getters["auth/token"];
    const role = store.getters["auth/role"];
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!token) {
      next({
        path: "/login",
      });
    } else {
      if (role == "representative") {
        if (
          to.name == "Sale" ||
          to.name == "GiveDebt" ||
          to.name == "InventoryRepresentative"
        ) {
          document.title = to.meta.title;
          next();
        } else {
          next({ name: "Sale" });
        }
      } else if (role == "local") {
        if (!to.path.startsWith("/report/profit") && to.name != "Dashboard") {
          document.title = to.meta.title;
          next();
        } else {
          next({ name: "Sale" });
        }
      } else {
        document.title = to.meta.title;
        next();
      }
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
