<template>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="types[type].color">
        <v-icon> {{ types[type].icon }} </v-icon>
        &nbsp;
        {{ text }}
        <template v-slot:action="{attrs}">
            <v-btn @click="snackbar = false;" v-bind="attrs" class="px-1" color="white black--text" outlined> ok </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        data() {
            return {
                snackbar: false,
                type: 'default',
                timeout: 3500,
                text: '',
                types: {
                    success: {
                        color: 'success accent-2',
                        icon: 'mdi-check-circle'
                    },
                    warning: {
                        color: 'warning accent-2',
                        icon: 'mdi-information'
                    },
                    error: {
                        color: 'error accent-2',
                        icon: 'mdi-alert-circle'
                    },
                    default: {
                        color: 'dark',
                        icon: ''
                    }
                }
            }
        },
        mounted() {
            this.emitter.$on('notify', (data) => {
                this.type = data.type || 'default';
                this.text = data.text;
                this.snackbar = true;
            });
        },
        beforeDestroy() {
            this.emitter.$off('notify');
        }
    }
</script>