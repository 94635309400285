import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import notification from "./components/notification.vue";
import VueSimpleAlert from "vue-simple-alert";
import ECharts from "vue-echarts";
import { use } from "echarts/core";

// import ECharts modules manually to reduce bundle size
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { GridComponent, TooltipComponent } from "echarts/components";

use([CanvasRenderer, BarChart, GridComponent, TooltipComponent]);

// register globally (or you can do it locally)
Vue.component("v-chart", ECharts);
Vue.use(VueSimpleAlert);
Vue.component("notification", notification);
import axios from "axios";
Vue.prototype.emitter = new Vue();
Vue.prototype.axios = axios;
axios.defaults.baseURL = "/api";
Vue.config.productionTip = false;
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");

new Vue({
  router,
  store,
  vuetify,
  axios,
  render: (h) => h(App),
}).$mount("#app");
