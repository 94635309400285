<template>
  <v-app
    :style="getPageTitle == 'CustomerInvoice' || getPageTitle == 'وەسڵی فرۆشتن' || getPageTitle == 'SupplierPurchase' ? 'background-color: white' : ''">
    <navbar :pageTitle="getPageTitle"
      v-if="getPageTitle != 'CustomerInvoice' && getPageTitle != 'InventoryPrint' && getPageTitle != 'وەسڵی فرۆشتن' && getPageTitle != 'SupplierHistory' && getPageTitle != 'PrintGiveDebt' && getPageTitle != 'SupplierPurchase'" />
    <!-- <v-app-bar
      app
      class="blue accent-2"
      dark
      bottom
    >
    </v-app-bar> -->


    <v-main>
      <transition name="scale" mode="out-in">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import navbar from './components/navbar.vue'
export default {

  components: {
    navbar
  },
  name: 'App',


  data: () => ({
    //
  }),
  computed: {
    getPageTitle() {
      return {
        Supplier: 'کۆمپانیاکان',
        Expense: 'خەرجییەکان',
        Dashboard: 'داشبۆرد',
        Report: 'ڕاپۆرتەکان',
        Item: 'کاڵاکان',
        ItemCategory: 'جۆری کاڵاکان',
        ExpenseCategory: 'جۆری خەرجییەکان',
        Unit: 'یەکەکان',
        Purchase: 'کڕینەکان',
        Sale: 'فرۆشتنەکان',
        Customer: 'کڕیارەکان',
        CustomerInvoice: 'CustomerInvoice',
        InventoryPrint: 'InventoryPrint',
        PrintGiveDebt: 'PrintGiveDebt',
        PrintSale: 'وەسڵی فرۆشتن',
        Debtors: 'قەرزارەکان',
        SupplierPurchase: 'SupplierPurchase',
      }[this.$route.name]
    }
  }
};
</script>
<style >
html {
  overflow-y: auto
}

@font-face {
  font-family: NRT;
  src: url('./assets/fonts/NRT-Reg.ttf');
}

* {
  font-family: NRT;
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}


.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

tr th {
  font-size: 20px !important;
}

tr td {
  font-size: 18px !important;
}

#app {
  background-color: rgb(238, 238, 238)
}
</style>